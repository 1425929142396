// src/routes.js
import React from 'react';
import { Route, Router } from 'react-router';

import Vehicle from './screens/Vehicle';
import Place from './screens/Place';
import Garage from './screens/Garage/Garage';

import Begin from './screens/Begin';

import SimpleServices from './screens/SimpleServices';
import Detailing from './screens/Detailing/Detailing';
import DetailingMore from './screens/Detailing/DetailingMore';
import Diagnostic from './screens/Diagnostic';
import Tires from './screens/Tires';
import NewTires from './screens/Tires/NewTires';
import TireSizeSelection from './screens/Tires/TireSizeSelection';

import Services from './screens/Services';
import Account from './screens/Account';
import Schedule from './screens/Schedule';
import ProactiveSchedule from './screens/ProactiveSchedule';
import OneClickConfirmation from './screens/OneClickConfirmation';
import Location from './screens/Location';
import Review from './screens/Review/Review';
import Finished from './screens/Finished';
import SaveMy from './screens/SaveMy';

import Login from './screens/Login';
import Logout from './screens/Logout';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';

import NotFound from './screens/NotFound';

import CheckIn from './screens/CheckIn';
import CheckinGeolocation from './screens/Geolocation/CheckinGeolocation';

import ApproveUpsellsItemized from './screens/ApproveUpsellsItemized/ApproveUpsellsItemized';
import FutureQuoteWrapper from './screens/ApproveUpsellsItemized/FutureQuoteWrapper';
import Quote from './screens/Quotes/Quote';
import VehicleInfoFromLookup from './screens/VehicleInfoFromLookup';
import AddHomeAddress from './screens/AddHomeAddress';
import VehicleEntry from './screens/VehicleEntry/VehicleEntry';
import OnlineAppointment from './screens/OnlineAppointment';
import Settings from './screens/Settings';
import PrivacyPolicy from './screens/PrivacyPolicy';

// http://stackoverflow.com/a/19079030 for janky date url

const Routes = (props) => (
  <Router {...props}>
    <Route path="/login" component={Login} />
    <Route path="/logout" component={Logout} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password/:rp_id" component={ResetPassword} />

    <Route path="/pricing/vehicle" component={Vehicle} />
    <Route path="/garage" component={Garage} />
    <Route path="/account" component={Garage} />
    <Route path="/settings" component={Settings} />

    <Route path="/pricing" component={Begin} />
    <Route path="/pricing/begin" component={Begin} />

    <Route path="/pricing/vehicle-lookup" component={VehicleEntry} />
    <Route
      path="/pricing/verify-vehicle-info/:plateState/:plateNumber"
      component={VehicleInfoFromLookup}
    />

    <Route path="/pricing/simple" component={SimpleServices} />
    <Route path="/pricing/diagnostic" component={Diagnostic} />
    <Route path="/pricing/services" component={Services} />
    <Route path="/pricing/detailing" component={Detailing} />
    <Route path="/pricing/detailing-more" component={DetailingMore} />
    <Route path="/pricing/tires" component={Tires} />

    {/*
        Jan 2021: We are not using the /tireDetail page for now.
        But we are still leaving the definition here in case someone uses an old link.
    */}
    <Route path="/pricing/tireDetail" component={Tires} />
    <Route path="/pricing/newtires" component={NewTires} />
    <Route path="/pricing/tiresize" component={TireSizeSelection} />

    <Route path="/pricing/account" component={Account} />
    <Route path="/pricing/location" component={Location} />
    <Route path="/pricing/place" component={Place} />
    <Route path="/pricing/schedule" component={Schedule} />
    <Route
      path="/reschedule/:customer_id/:sw_id/:new_date"
      component={ProactiveSchedule}
    />
    <Route
      path="/reschedule/:customer_id/:sw_id"
      component={ProactiveSchedule}
    />
    <Route
      path="/proactive-scheduling/:customer_id/:sw_id/:new_date"
      component={ProactiveSchedule}
    />
    <Route
      path="/proactive-scheduling/:customer_id/:sw_id"
      component={ProactiveSchedule}
    />
    <Route
      path="/one-click-confirmation/:type"
      component={OneClickConfirmation}
    />
    <Route path="/update-payment/:token" component={OnlineAppointment} />
    <Route path="/appointment/:sw_id/:token" component={OnlineAppointment} />
    <Route path="/pricing/review" component={Review} />
    <Route path="/pricing/finished" component={Finished} />

    <Route path="/pricing/save" component={SaveMy} />

    <Route path="/checkin/:customer_id/:sw_id" component={CheckIn} />
    <Route
      path="/checkin-geo/:customerId/:swId"
      component={CheckinGeolocation}
    />

    <Route
      path="/approve-services/:customer_id/:sw_id"
      component={ApproveUpsellsItemized}
    />
    <Route
      path="/receipt/:customer_id/:sw_id"
      component={ApproveUpsellsItemized}
    />
    <Route path="/quote/:customer_id/:sw_id" component={FutureQuoteWrapper} />
    <Route path="/quote/:quote_uuid" component={Quote} />

    <Route path="/add-home-address" component={AddHomeAddress} />

    <Route path="/" component={Tires} />
    <Route path="/public/index.html" component={Begin} />

    <Route path="/privacy-policy" component={PrivacyPolicy} />

    <Route path="*" component={NotFound} />
  </Router>
);

export default Routes;
